










































































// @ is an alias to /src
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component({
	components: {}
})
export default class Home extends Vue {

	private getImgUrl(value: number): string | null {
		const basePath = 'gallery/';

		let image = '';
		switch (value) {
			case 0:
				image = 'skiing1.jpg';
				break;
			case 1:
				image = 'sign.jpg';
				break;
			case 2:
				image = 'machine.jpg';
				break;
			case 3:
				image = 'hutt2.jpg';
				break;
			case 4:
				image = 'night.jpg';
				break;
			case 5:
				image = 'skiing3.jpg';
				break;
			case 6:
				image = 'hutt.jpg';
				break;
			case 7:
				image = 'skiing2.jpg';
				break;
			case 8:
				image = 'team2.jpg';
				break;
			default:
				return null;
		}

		return basePath + image;
	}

}
